import React from 'react'
import DocumentLayout from '../../DocumentLayout'
import Centered from '../../Centered'
import Icon from '../../shared/ui/Icon'

function QualityGuidelines() {
  return (
    <DocumentLayout className="subpage-blue">
      <br />
      <Centered>
        <div className="spacing">
          <h1>Marketplace Author Quality Guidelines</h1>

          <p className="text-xlarge">
            At Questionable.io, we hand-choose our authors who have privileges to publish content into the Marketplace.
            We also ask these authors to adhere to certain guidelines to keep the content that we sell at the highest
            quality that we can achieve:
          </p>

          <section>
            <h2 className="icon-text">
              <Icon icon="check-circle-solid" color="white" size={1} />
              <span>Concepts over Terminology</span>
            </h2>
            <p>
              One of the things that makes Questionable a great tool is its code-enabled questions. This uniquely means
              that we can test for coding concepts over coding jargon. Many talented coders might know how a concept
              works but not necessarily the correct terminology for that concept. Questions are higher quality when they
              assess someone's real coding knowledge instead of their knowledge of the correct terms. On Questionable,
              we want to limit the number of terminology questions to under 10% per test.
            </p>
          </section>

          <section>
            <h2 className="icon-text">
              <Icon icon="check-circle-solid" color="white" size={1} />
              <span>Avoid Process-of-Elimination</span>
            </h2>
            <p>
              We've all taken tests that allowed us to figure out the correct answer because of poorly written
              distractor options (incorrect options). If a test taker can do well on your test because they're good at
              eliminating the distractor options, then it's not a very good test.
            </p>
            <p>
              At Questionable.io, we're taking steps to ensure that our questions and their answers accurately depict
              the test takers true knowledge of the subject, for better or worse. This means more attention to choosing
              good distractor options that limit process-of-elimination while also being fair and not "tricks" to figure
              out.
            </p>
          </section>

          <section>
            <h2 className="icon-text">
              <Icon icon="check-circle-solid" color="white" size={1} />
              <span>Avoid Trick Questions</span>
            </h2>
            <p>
              Nobody likes a trick question. They're not fair and they don't help to depict someone's true knowledge of
              the subject. While this area can be subjective at times, we attempt to make all of our questions fair and
              more about real content knowledge, not esoteric knowledge or requiring the test taker to notice very small
              details in order to get the answer correct.
            </p>
          </section>

          <section>
            <h2 className="icon-text">
              <Icon icon="check-circle-solid" color="white" size={1} />
              <span>Common Conventions</span>
            </h2>
            <p>
              Sometimes programming languages provide a lot of freedom when writing code with various styles. It
              wouldn't be fair if the test author introduced their personal coding biases into the test such that the
              test taker would have to agree with those biases in order to succeed. In order to create fair tests, we
              ask Marketplace Test Authors to write questions with the most commonly known conventions for that
              language.
            </p>
          </section>

          <section>
            <h2 className="icon-text">
              <Icon icon="check-circle-solid" color="white" size={1} />
              <span>Avoid Coding Opinions</span>
            </h2>
            <p>
              If you've ever been around two or more developers talking about complex coding concepts, you're probably
              aware that there are lots of vastly different opinions in software development for what constitutes
              quality, performant, safe, and well written code. Marketplace Test Authors are asked to create questions
              in ways that focus on the facts of the the language or tool instead of asking questions which force the
              test taker to be agreeable with the authors biases.
            </p>
          </section>

          <section>
            <h2 className="icon-text">
              <Icon icon="check-circle-solid" color="white" size={1} />
              <span>Tag Use</span>
            </h2>
            <p>
              All questions in the marketplace will be well-tagged. This will not only help with searching your question
              bank and composing new tests from your purchased ones, but it will also ensure that analytics like the Gap
              Analysis report are of high quality.
            </p>
          </section>
        </div>
      </Centered>
      <br />
    </DocumentLayout>
  )
}

export default QualityGuidelines
